import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './Icon';


const useStyles = makeStyles((theme) => ({
    toolbar:{
      height:70,
      flexDirection:'row', 
      justifyContent:'space-between',
      backgroundColor:'#1f2022',
      alignItems:'center'
    }
}));



export default function NavBar() {

    const classes = useStyles();

    return (
       
        <AppBar position='static'>
            <Toolbar className={classes.toolbar}>
                {/* < <ChessLogoLoading size={59}/> */}
                <Icon size={50} />

        
                <p style={{fontSize:20, textAlign:'right'}}><b>Resonance</b> Trading</p>
                {/* <div style={{display:'flex', flexDirection:'row'}}>
              


                </div> */}
            </Toolbar>
        </AppBar>
      
    );
}
