import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import david from '../art/david.jpg';
import tucker from '../art/tucker.jpg';


import SimpleAccordion from './SimpleAccordion';


const useStyles = makeStyles((theme) => ({
    toolbar:{

    }
}));



export default function Landing() {

    const classes = useStyles();

    return (
       
        <div style={{ display:'flex', flexDirection:'column', alignItems:'center'}}>


            <div style={{marginBottom:0, marginTop:20, maxWidth:900, width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                
                <div style={{paddingLeft:15, display:'flex', flexDirection:'row', alignItems:'center', height:45, justifyContent:'left', borderLeft:'5px solid #020024',}}>
                    <p style={{fontWeight:'bold', fontSize:35}}> Quantitative Trading Firm</p>
                </div>


            </div>



            {/* <Divider/> */}

            <div style={{marginBottom:40, maxWidth:900, width:'100%'}}>


        
            </div>


            {/* <div className='stats-card' style={{width:'100%', maxWidth:875}}>

                    <div style={{paddingRight:15, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>

                        <p style={{color:'white', fontSize:20, fontWeight:'bold', margin:0, padding:0, marginBottom:10}}>$30,000</p>

                        <p style={{color:'white', margin:0, padding:0}}>Assets</p>

                    </div>


                    <Divider orientation="vertical" flexItem />

                    <div style={{paddingRight:15, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>

                        <p style={{color:'white', fontSize:20, fontWeight:'bold', margin:0, padding:0, marginBottom:10}}>??%</p>

                        <p style={{color:'white', margin:0, padding:0 }}>APY</p>

                    </div>

            </div> */}


            {/* <div style={{marginTop:40, maxWidth:900, width:'100%'}}> */}
            <div style={{marginTop:10, maxWidth:900, width:'100%'}}>



                <p style={{textAlign:'left', margin:0, padding:0, marginBottom:10, fontWeight:'bold', fontSize:20}}>Methods</p>
                
                <Divider/>
                

                <p style={{fontWeight:'normal', fontSize:14,padding:0, margin:0, marginTop:10 }} >Resonannce uses propietary automated statisitcal strategies to trade assets.</p>


                <div style={{height:40}}/>
   
                <p style={{textAlign:'left',  margin:0, padding:0, marginBottom:10,  fontWeight:'bold', fontSize:20}}>Webhook Trading Signals</p>
                
                <Divider/>

                <p style={{fontWeight:'normal', fontSize:14,padding:0, margin:0, marginTop:10, marginBottom:50 }}>Resonance offers access to its propietary signals in the form of webhooks. You will recieve updates when to 
                 enter and exit positions. Strategies are calibrated for each asset available and are independently optimzed for bull and bear market conditions. Please book a call to learn more about backtest results and current performance. 
                 <br/>
                 <br/>

                 <b>"NEO"</b>
        
                 
                 
                 </p>

                {/* <div style={{height:40}}/> */}
   
                <p style={{textAlign:'left',  margin:0, padding:0, marginBottom:10,  fontWeight:'bold', fontSize:20}}>REST API</p>
                
                <Divider/>

                <p  style={{fontWeight:'normal', fontSize:14,padding:0, margin:0, marginTop:10, marginBottom:50 }} >
                In addition to price data, Resonance maintains a comprehensive block history of both the Ethereum and Polygon chains. We have access to all blocks on these networks since their inception, allowing for in-depth research and analysis of trends over time and the devlopment of novel strategies.
                <br/><br/>We  calculate 24 metrics that provide valuable insights into usage patterns, such as daily transaction volumes, average transaction fees, unique addresses involved in transactions, and the total value of transactions processed on the network. 

                <br/><br/>If you are interested in gaining programmatic access to this data in the form of a REST API, please contact the following email. 

                <br/><br/><b>davidg3@protonmail.com</b>

                <br/><br/>Metrics are available in a 10 minute or hourly interval.

                </p>


                <p style={{textAlign:'left', margin:0, padding:0, marginBottom:10,marginTop:50,  fontWeight:'bold', fontSize:20}}>Team</p>
                
            
                <Divider/>



 <div style={{marginTop:20, marginBottom:40, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around'}}>


                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>

                        <Avatar  sx={{ width: 70, height: 70 }} alt="Remy Sharp" src={david} />


                        <a style={{color:"black"}} href='https://dgoldstein.us'><p style={{padding:0, margin:0, marginTop:15, fontSize:14, fontWeight:'bold'}}>David Goldstein</p></a>



                    </div>

                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <Avatar  sx={{ width: 70, height: 70 }} alt="Remy Sharp" src={tucker} />


                            <a style={{color:'black'}} href='https://www.linkedin.com/in/tucker-weed/'><p style={{padding:0, margin:0, marginTop:15, fontSize:14, fontWeight:'bold'}}>Tucker Weed</p></a>

                        
                    </div>

                </div>




            </div>

            {/* <SimpleAccordion/> */}





    
            




        </div>
      
    );
}
